import { BackendMethod } from '@memberspot/shared/model/backend';
import { GetPermissionDto } from '@memberspot/shared/model/permission';
import {
  CreateSchoolDto,
  UpdateSchoolDto,
  UpdateSelfSignupDto,
} from '@memberspot/shared/model/school';

import { StripeCancelSubscriptionData } from '../../billing/subscription.model';
import { ChildCustomerDto } from '../../dtos/customers/add-child.customers.dto';
import { ChangeCustomerStartDateDto } from '../../dtos/customers/start-date.customers.dto';
import { PaymentMethodDto } from '../../dtos/payment/stripe/payment-method.stripe.dto';
import { UpdateVatIdDto } from '../../dtos/payment/stripe/update-address.dto';
import {
  AddSchoolAdminDto,
  RemoveSchoolAdminDto,
  SetRoleDto,
} from '../../dtos/school/add-school-admin.dto';
import { ChangeSchoolUrlDto } from '../../dtos/school/change-school-url.dto';
import { ConvertVideoDto } from '../../dtos/upload.dto';
import { StripeCheckoutData } from './../../billing/checkout.model';

const SchoolEndpoints = {
  VALIDATED_CNAME: (data: ChangeSchoolUrlDto) => ({
    url: `/schools/validate-cname/${data.schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  CHANGE_URL: (data: ChangeSchoolUrlDto) => ({
    url: `/schools/update-url/${data.schoolId}`,
    data,
    method: BackendMethod.PATCH,
  }),
  CREATE: (data: CreateSchoolDto) => ({
    url: `/schools/create`,
    data,
    method: BackendMethod.POST,
  }),
  DELETE: (schoolId: string) => ({
    url: `/schools/${schoolId}`,
    method: BackendMethod.DELETE,
  }),
  UPDATE: (schoolId: string, data: UpdateSchoolDto) => ({
    url: `/schools/update/${schoolId}`,
    data,
    method: BackendMethod.PATCH,
  }),
  UPDATE_SELF_SIGNUP: (schoolId: string, data: UpdateSelfSignupDto) => ({
    url: `/schools/update-self-signup/${schoolId}`,
    data,
    method: BackendMethod.PATCH,
  }),
  SET_CUSTOM_MAIL_SENDER: (schoolId: string, newValue: boolean) => ({
    url: `/schools/set-custom-mail-sender/${schoolId}`,
    data: {
      newValue,
    },
    method: BackendMethod.POST,
  }),
  GET_SCHOOL: (schoolId: string) => ({
    url: `/schools/${schoolId}`,
    method: BackendMethod.GET,
  }),
};

const CustomerEndpoints = {
  ADD_CHILD_CUSTOMER: (data: ChildCustomerDto) => ({
    url: '/customers/add-child',
    data,
    method: BackendMethod.PATCH,
  }),
  REMOVE_CHILD_CUSTOMER: (data: ChildCustomerDto) => ({
    url: '/customers/remove-child',
    data,
    method: BackendMethod.PATCH,
  }),
  DISABLE_CUSTOMER: (customerId: string) => ({
    url: `/customers/disable/${customerId}`,
    emptyData: {},
    method: BackendMethod.POST,
  }),
  CHANGE_START_DATE: (
    customerId: string,
    data: ChangeCustomerStartDateDto,
  ) => ({
    url: `/customers/change-start-date/${customerId}`,
    data,
    method: BackendMethod.POST,
  }),
  RESTORE_SCHOOL: (schoolId: string) => ({
    url: `/customers/restore-school/${schoolId}`,
    method: BackendMethod.POST,
  }),
  SET_CSV_IMPORT_ENABLED: (schoolId: string, newValue: boolean) => ({
    url: `/customers/set-csv-import-enabled`,
    data: {
      schoolId,
      newValue,
    },
    method: BackendMethod.POST,
  }),
};

const StripeEndpoints = {
  createCheckout: () => `/payment/stripe/checkout`,
  cancelAccount: () => '/payment/stripe/cancel-subscription',
  updateCustomerAddress: () => `/payment/stripe/update-address`,

  UPGRADE_PLAN: (data: StripeCheckoutData) => ({
    url: `/payment/stripe/upgrade`,
    method: BackendMethod.POST,
    data,
  }),

  CANCEL_ACCOUNT: (data: StripeCancelSubscriptionData) => ({
    url: `/payment/stripe/cancel-subscription`,
    method: BackendMethod.POST,
    data,
  }),

  CHECK_VATID: (schoolId: string, data: UpdateVatIdDto) => ({
    url: `/payment/stripe/check-vatid/${schoolId}`,
    method: BackendMethod.POST,
    data,
  }),

  UPDATE_VATID: (schoolId: string, data: UpdateVatIdDto) => ({
    url: `/payment/stripe/update-vatid/${schoolId}`,
    method: BackendMethod.PATCH,
    data,
  }),

  GET_CUSTOMER: (schoolId: string) => ({
    url: `/payment/stripe/customers/${schoolId}`,
    method: BackendMethod.GET,
  }),
  GET_SUBSCRIPTION: (schoolId: string) => ({
    url: `/payment/stripe/subscription/${schoolId}`,
    method: BackendMethod.GET,
  }),
  SET_SUBSCRIPTION_PAYMENT_METHOD: (
    schoolId: string,
    data: PaymentMethodDto,
  ) => ({
    url: `/payment/stripe/subscription-payment/${schoolId}`,
    data,
    method: BackendMethod.PATCH,
  }),

  getInvoices: (schoolId: string) => `/payment/stripe/invoices/${schoolId}`,

  GET_PAYMENT_METHODS: (schoolId: string) => ({
    url: `/payment/stripe/methods/${schoolId}`,
    method: BackendMethod.GET,
  }),
  DELETE_PAYMENT_METHODS: (schoolId: string, data: PaymentMethodDto) => ({
    url: `/payment/stripe/methods-delete/${schoolId}`,
    data,
    method: BackendMethod.POST,
  }),

  CREATE_SETUP_INTENT: (schoolId: string) => ({
    url: `/payment/stripe/methods/${schoolId}`,
    method: BackendMethod.POST,
  }),
  CREATE_PORTAL_SESSION: (schoolId: string) => ({
    url: `/payment/stripe/portal/${schoolId}`,
    method: BackendMethod.POST,
  }),
  CONFIRM_3D_PAYMENT: (schoolId: string) => ({
    url: `/payment/stripe/confirm-payment/${schoolId}`,
    method: BackendMethod.POST,
  }),

  addCoupon: () => '/payment/stripe/add-coupon',
};

const UploadEndpoints = {
  convertVideo: (data: ConvertVideoDto) => ({
    url: '/upload/convert-video',
    data,
  }),
};

const SchoolAdminsEndpoints = {
  ADD_ADMIN: (data: AddSchoolAdminDto) => ({
    url: `/school-admin/add-admin/${data.schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  REMOVE_ADMIN: (data: RemoveSchoolAdminDto) => ({
    url: `/school-admin/remove-admin/${data.schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  GET_ADMINS: (schoolId: string) => ({
    url: `/school-admin/${schoolId}`,
    method: BackendMethod.GET,
  }),
  SET_ROLE: (data: SetRoleDto) => ({
    url: `/school-admin/set-role/${data.schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  GET_ADMIN_PERMISSION: (schoolId: string) => ({
    url: `/school-admin/permissions/${schoolId}`,
    method: BackendMethod.GET,
  }),
  GET_DUPLICATE_COURSE_PERMISSION_SCHOOLS: () => ({
    url: `/school-admin/duplicate-course-schools`,
    method: BackendMethod.GET,
  }),
  GET_USER_SCHOOLS_WITH_PERMISSION: (data: GetPermissionDto) => ({
    url: `/school-admin/schools-with-rights`,
    data,
    method: BackendMethod.POST,
  }),
};

export const BackendEndpoints = {
  CUSTOMER: CustomerEndpoints,
  STRIPE: StripeEndpoints,
  SCHOOL: SchoolEndpoints,
  UPLOAD: UploadEndpoints,
  SCHOOL_ADMINS: SchoolAdminsEndpoints,
} as const;
